import React from 'react';
import StackWithButtons from "../../../../components/StackWithButtons";
import {Image, ListGroup} from "react-bootstrap";
import swipeAndTube from "../../../../assets/images/swipe_package_with_barcode.png";
import {Link} from "react-router-dom";

const Intro = (props: {
    next: () => void;
}) => {
    return (
        <StackWithButtons title="Add a Test Kit"
                          next={props.next}>
            <ListGroup>
                <ListGroup.Item>
                    <div className="text-center">
                        <Image src={swipeAndTube} className="bg-primary-lighter bg-gradient border border-primary"
                               width="256px" alt="Swipe and Tube" thumbnail/>
                    </div>
                </ListGroup.Item>
                <ListGroup.Item className="small text-start" variant="light">
                    <ol className="flex-grow-1">
                        <li>Make sure that you have a test kit purchased from <a
                            href="https://s-wipe.shop" target="_blank" rel="noreferrer">s-wipe.shop</a></li>
                        <li>Locate the barcode on a side of the test kit</li>
                        <li>After clicking <Link to="#" onClick={props.next}>Next</Link>, scan this barcode or enter
                            the barcode manually</li>
                    </ol>
                    <div className="text-end">
                        <a href="https://s-wipe.shop/instructions" target="_blank" rel="noreferrer">Read more...</a>
                    </div>
                </ListGroup.Item>
            </ListGroup>
        </StackWithButtons>
    )
}

export default Intro;