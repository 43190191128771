import RoundedButton from "../../../components/RoundedButton";
import {Link} from "react-router-dom";
import StackWithButtons from "../../../components/StackWithButtons";
import React from "react";

const ThankYou = (props: {
    prev: () => void;
}) => {
    return (
        <StackWithButtons title="Thank you!"
                          description="Once your results are ready, we will notify you via email"
                          prev={props.prev}>
            <Link to="/account">
                <RoundedButton>Finish</RoundedButton>
            </Link>
        </StackWithButtons>
    )
}

export default ThankYou;