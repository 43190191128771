import React from 'react';
import Column from "../../components/Column";
import {Form, InputGroup, Stack} from "react-bootstrap";
import {Envelope, Lock} from "react-bootstrap-icons";
import {Link, useNavigate} from "react-router-dom";
import RoundedButton from "../../components/RoundedButton";
import {completeSignInWithEmailLink, mapAuthCodeToMessage} from "../../firebase";
import {AuthError} from 'firebase/auth';


const SignInWithLink = () => {

    const [validated, setValidated] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [repeatPassword, setRepeatPassword] = React.useState("");
    const [consent, setConsent] = React.useState(false);
    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() && password === repeatPassword && consent) {
            completeSignInWithEmailLink(email, window.location.href, password)
                .then(() => navigate("/account"))
                .catch((error: AuthError) => {
                    console.error(error.code, error.message);
                    const message = mapAuthCodeToMessage(error.code);
                    setErrorMessage(message || error.message);
                    setValidated(false);
                });
        }
    }

    return (
        <Column>
            <Form validated={validated} onSubmit={handleSubmit}>
                <Stack className="d-flex justify-content-center text-center vh-custom" gap={3}>
                    <div className="h2 fw-bold">Complete Sign Up</div>
                    <div className="error text-danger">{errorMessage}</div>
                    <InputGroup hasValidation>
                        <InputGroup.Text><Envelope/></InputGroup.Text>
                        <Form.Control type="email" name="email" value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      placeholder="Enter your email" required autoFocus/>
                    </InputGroup>
                    <InputGroup hasValidation>
                        <InputGroup.Text><Lock/></InputGroup.Text>
                        <Form.Control type="password" name="password" value={password}
                                      onChange={(e) => setPassword(e.target.value)}
                                      placeholder="Enter your password" required/>
                    </InputGroup>
                    <InputGroup hasValidation>
                        <InputGroup.Text><Lock/></InputGroup.Text>
                        <Form.Control type="password" name="repeatPassword" value={repeatPassword}
                                      onChange={(e) => setRepeatPassword(e.target.value)}
                                      placeholder="Enter your password" required
                                      isInvalid={password !== repeatPassword}/>
                    </InputGroup>
                    <InputGroup hasValidation>
                        <Form.Check.Label>
                            <Form.Check.Input type="checkbox" name="consent" className="border-primary me-2"
                                              checked={consent} required
                                              onChange={(e) => setConsent(e.target.checked)}/>
                            <span>I agree to Bileomix's&nbsp;</span>
                            <Link to="https://s-wipe.shop/terms-and-conditions" target="_blank">Terms of
                                Service</Link>
                            <span>&nbsp;and&nbsp;</span>
                            <Link to="https://s-wipe.shop/privacy-policy" target="_blank">Privacy Policy</Link>
                        </Form.Check.Label>
                    </InputGroup>
                    <RoundedButton variant="primary" type="submit" className="">Sign In</RoundedButton>
                </Stack>
            </Form>
        </Column>
    )
}

export default SignInWithLink;