import React, {useState} from 'react';
import StackWithButtons from "../../../components/StackWithButtons";
import MultiOptionSelector from "../../../components/MultiOptionSelector";
import {useUserAuth} from "../../../contexts/UserAuthContext";

const GutSymptoms = ({prev, next}: {
    prev: () => void,
    next: () => void,
}) => {

    const {updateUserData, userData} = useUserAuth();
    const gutSymptoms = [
        'Diarrhea', 'Heartburn', 'Constipation', 'Acid Reflux', 'Stomach Pain', 'Bloating', 'Nausea', 'Indigestion'];
    const [selectedSymptoms, setSelectedSymptoms] = useState<Array<string>>(userData.gutSymptoms ?? []);

    function handleNext() {
        updateUserData('gutSymptoms', selectedSymptoms)
        .then((result: boolean) => {
            if (result) {
                next();
            }
        });
    }

    return (
        <StackWithButtons title="Gut Symptoms"
                          description="Do you regularly experience any of these symptoms:"
                          prev={prev} next={handleNext}>
            <MultiOptionSelector options={gutSymptoms} selectedOptions={selectedSymptoms}
                                 setSelectedOptions={setSelectedSymptoms} hasOtherOption={true} hasNoneOption={true}/>
        </StackWithButtons>
    )
}

export default GutSymptoms;