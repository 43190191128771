class Test {
    testId: string;
    kitId: string;
    tubeNumber: number;
    date: Date | undefined;

    constructor(testId: string, kitId: string, tubeNumber: number, date?: Date) {
        this.testId = testId;
        this.kitId = kitId;
        this.tubeNumber = tubeNumber;
        this.date = date;
    }
}

export default Test;