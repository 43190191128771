import {Stack, ToggleButton} from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import Button from "react-bootstrap/Button";
import {useUserAuth} from "../../../../contexts/UserAuthContext";
import {useState} from "react";
import StackWithButtons from "../../../../components/StackWithButtons";
import MultiOptionSelector from "../../../../components/MultiOptionSelector";
import React from 'react';

const Supplements = (props: {
    prev: () => void;
    next: (selected: Array<string>) => void;
}) => {
    const {updateScanData, getLastTestExcept} = useUserAuth();
    // const previousTest = getLastTestExcept(barcode);
    const supplements = ["AXOS Supplement", "GOS Supplement", "Inulin Supplement", "Maise/Corn Supplement",
        "Polydextrose Supplement", "Wheat Supplement", "Whole Grain Supplement", "XOS Supplement"];
    const [selectedSupplements, setSelectedSupplements] = useState<Array<string>>([]);

    return (
        <StackWithButtons title="Fiber Supplements"
                          description="What fiber supplements do you take? Skip if you don't take any fiber supplements"
                          prev={props.prev}
                          next={() => props.next(selectedSupplements)}>
            <MultiOptionSelector options={supplements} selectedOptions={selectedSupplements}
                                 setSelectedOptions={setSelectedSupplements}/>
        </StackWithButtons>
    )
}

export default Supplements;