import React from "react";
import Button from 'react-bootstrap/Button';

function RoundedButton({...props}) {

    props.className = "rounded-5 w-100 " + props.className ?? '';

    return (
        <Button {...props}>
            {props.children}
        </Button>
    )
}

export default RoundedButton;