import React from 'react';
import StackWithButtons from "../../../components/StackWithButtons";

const PackAndShip = (props: {
    prev: () => void;
    next: () => void;
}) => {
    return (
        <StackWithButtons title="Pack and Ship" prev={props.prev} next={props.next} className="text-start">
            <p>
                <span className="fw-bold">1. Store the tubes:</span>&nbsp;
                Keep collected samples in the provided kit box or shipping envelope.
            </p>
            <p>
                <span className="fw-bold">2. Package the samples:</span>&nbsp;
                Once you have collected the desired number of samples, place the sealed tubes into the provided
                shipping envelope.
            </p>
            <p>
                <span className="fw-bold">3. Send the samples:</span>&nbsp;
                Drop the envelope off at your nearest postal service location.
            </p>
            <p className="small"><span className="fw-bold text-primary">Why It Matters:</span> Timely shipping ensures
                that the samples
                reach our lab in optimal condition for analysis. The preservation fluid maintains sample integrity
                during transit.
            </p>
        </StackWithButtons>
    // <>
    //     <Stack className="justify-content-center" gap={3}>
    //         <h3>Pack and ship your sample</h3>
    //         <p>
    //             <span className="fw-bold">1. Store the tubes:</span>&nbsp;
    //             Keep collected samples in the provided kit box or shipping envelope.
    //         </p>
    //         <p>
    //             <span className="fw-bold">2. Package the samples:</span>&nbsp;
    //             Once you have collected the desired number of samples, place the sealed tubes into the provided
    //             shipping envelope.
    //         </p>
    //         <p>
    //             <span className="fw-bold">3. Send the samples:</span>&nbsp;
    //             Drop the envelope off at your nearest postal service location.
    //         </p>
    //         <p className="small"><span className="fw-bold text-primary">Why It Matters:</span> Timely shipping ensures
    //             that the samples
    //             reach our lab in optimal condition for analysis. The preservation fluid maintains sample integrity
    //                 during transit.
    //             </p>
    //         </Stack>
    //         <Stack direction="horizontal" gap={3}>
    //             <Button className="ms-auto w-50" onClick={next}>Next</Button>
    //         </Stack>
    //     </>
    )
}

export default PackAndShip;