import React, {useState} from "react";
import StackWithButtons from "../../../../components/StackWithButtons";
import SingleOptionSelector from "../../../../components/SingleOptionSelector";

const AlcoholConsumption = (props: {
    prev: () => void;
    next: (selected: string) => void
}) => {

    // const previousTest = getLastTestExcept(barcode);
    const alcoholConsumptionAnswers = ["Never", "Rarely (less than once a month)",
        "Occasionally (1-3 times a month)", "Frequently (1-3 times a week)", "Daily"];
    const [selectedAlcoholConsumption, setSelectedAlcoholConsumption] = useState('Never');  // previousTest?.alcoholConsumption

    return (
        <StackWithButtons title="Alcohol Consumption"
                          description="How frequently did you consume alcoholic beverages in the past three months?"
                          prev={props.prev}
                          next={() => props.next(selectedAlcoholConsumption)}
                          isNextAvailable={selectedAlcoholConsumption.length > 0}>
            <SingleOptionSelector options={alcoholConsumptionAnswers} value={selectedAlcoholConsumption}
                                  setValue={setSelectedAlcoholConsumption}/>
        </StackWithButtons>
    )
}

export default AlcoholConsumption;