import {Link, useNavigate} from 'react-router-dom';
import {Form, InputGroup, Stack} from "react-bootstrap";
import {Envelope, Lock} from "react-bootstrap-icons";
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import RoundedButton from "../components/RoundedButton";
import {useEffect, useState} from "react";
import {useUserAuth} from "../contexts/UserAuthContext";
import {mapAuthCodeToMessage} from "../firebase";
import Column from "../components/Column";

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const [validated, setValidated] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {logIn, auth} = useUserAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.currentUser) {
            navigate("/account");
        }
    }, [auth.currentUser, navigate]);


    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity()) {
            try {
                await logIn(formData.email, formData.password)
                    .then(() => {
                        navigate('/account');
                    });
            } catch (error) {
                console.log(error.code, error.message);
                setErrorMessage(mapAuthCodeToMessage(error.code));
            }
        }
        setValidated(true);
    };

    return (
        <Column>
            <Form validated={validated} onSubmit={handleSubmit}>
                <Stack className="text-center my-6" gap={3}>
                    <div className="h2 fw-bold">Sign In</div>
                    <div className="error text-danger">{errorMessage}</div>
                    <InputGroup hasValidation>
                        <InputGroup.Text><Envelope/></InputGroup.Text>
                        <Form.Control type="email" name="email" value={formData.email} onChange={handleChange}
                                      placeholder="Enter your email" required autoFocus/>
                    </InputGroup>
                    <InputGroup hasValidation>
                        <InputGroup.Text><Lock/></InputGroup.Text>
                        <Form.Control type={showPassword ? "text" : "password"} name="password"
                                      value={formData.password} onChange={handleChange}
                                      placeholder="Enter your password" required/>
                        <InputGroup.Text onClick={() => setShowPassword(!showPassword)}
                                         style={{cursor: 'pointer'}}>
                            {showPassword ? <FaEye/> : <FaEyeSlash/>}
                        </InputGroup.Text>
                    </InputGroup>
                    <div className="text-end">
                        <Link to="/forgotpassword"><p className="small">Forgot password?</p></Link>
                    </div>
                    <RoundedButton variant="primary" type="submit">Sign In</RoundedButton>
                    <div>
                        Don't have an account? <Link to="/register">Sign up</Link>
                    </div>
                </Stack>
            </Form>
        </Column>
    )
}

export default Login;