import React, {useState} from 'react';
import StackWithButtons from "../../../components/StackWithButtons";
import MultiOptionSelector from "../../../components/MultiOptionSelector";
import {useUserAuth} from "../../../contexts/UserAuthContext";

const GutConditions = ({prev, next}: {
    prev: () => void,
    next: () => void,
}) => {

    const {updateUserData, userData} = useUserAuth();
    const gutConditions = [
        'Irritable Bowel Syndrome (IBS)', 'Inflammatory Bowel Disease (IBD)', "Crohn's disease",
        'Small Intestinal Bacterial Overgrowth (SIBO)', 'Celiac Disease', 'Gastroesophageal Reflux Disease (GERD)'
    ];
    const [selectedConditions, setSelectedConditions] = useState<Array<string>>(userData.gutConditions ?? []);

    function handleNext() {
        updateUserData('gutConditions', selectedConditions)
        .then((result: boolean) => {
            if (result) {
                next();
            }
        });
    }

    return (
        <StackWithButtons title="Gut Conditions"
                          description="Please select if you have any of the following gut conditions:"
                          prev={prev} next={handleNext}>
            <MultiOptionSelector options={gutConditions} selectedOptions={selectedConditions}
                                 setSelectedOptions={setSelectedConditions} hasOtherOption={true} hasNoneOption={true}/>
        </StackWithButtons>
    )
}

export default GutConditions;