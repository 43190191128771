import {Link, useNavigate} from 'react-router-dom';
import Stack from 'react-bootstrap/Stack';
import RoundedButton from "../components/RoundedButton";
import Column from "../components/Column";
import {useUserAuth} from "../contexts/UserAuthContext";
import {useEffect} from "react";
// import swipeLogo from "../assets/images/s-wipe-logo.png";
import swipeLogo from "../assets/images/s-wipe-logo_20240805_primary-dark.png";
import {Container, Navbar} from "react-bootstrap";

const Landing = () => {

    const {user} = useUserAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate("/account");
        }
    }, [user, navigate]);

    return (
        <>
            <Navbar className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="#">
                        <img src={swipeLogo} color="red" height="48px" alt="S'Wipe"/>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text className="fw-bold text-center small">
                            <span className="d-none d-sm-block">For more information, visit<br/></span>
                            <span className="d-sm-none">Visit<br/></span>
                            <a href="https://s-wipe.shop" target="_blank" rel="noreferrer">https://s-wipe.shop</a>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Column>
                <Stack className="text-center my-1 my-md-3 gap-md-3" gap={1}>
                    <h4 className="text-primary-dark mb-3">Welcome to S'Wipe gut health test!</h4>
                    <p className="py-2 p-md-4 px-1 bg-primary-dark bg-gradient text-white rounded-3 small">
                        The gut microbiome plays a crucial role in your digestion, immune support, stress and anxiety.
                        Having diverse gut bacteria is linked to reduced risk of heart disease, diabetes, asthma,
                        depression, and allergies.
                    </p>
                    <p>
                        Take control of your digestive health today with S'Wipe’s easy-to-use testing kit:&nbsp;
                        <a href="https://s-wipe.shop" target="_blank" rel="noreferrer">https://s-wipe.shop</a>
                    </p>
                    <Link to="/login">
                        <RoundedButton variant="primary">Login</RoundedButton>
                    </Link>
                    <Link to="/register">
                        <RoundedButton variant="secondary">Sign Up</RoundedButton>
                    </Link>
                    <p className="small px-1 mt-1 bg-light">If you have any questions or comments about the app or the
                        sample kit,
                        please email them to <Link
                            to="mailto:info@bileomix.com">info@bileomix.com</Link>.
                        We appreciate your feedback!
                    </p>
                </Stack>
            </Column>
        </>
    )
}

export default Landing;