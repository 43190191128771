import {Navigate, Outlet} from "react-router-dom";
import {useUserAuth} from "../contexts/UserAuthContext";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import swipeLogo from "../assets/images/s-wipe-logo_20240805_white.png";
import {faFileLines, faHome, faRightFromBracket, faVials, faUser, faUserTie} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ProtectedRoutes = () => {
    const {user, logOut} = useUserAuth();

    if (user === null || user === undefined || Object.keys(user).length === 0) {
        return <Navigate to="/"/>;
    }

    if (!user.emailVerified) {
        return <Navigate to="/verify-email"/>;
    }

    return (
        <>
            <Navbar expand="lg" className="bg-primary-dark text-white" variant="dark">
                <Container>
                    <Navbar.Brand href="/account">
                        <img src={swipeLogo} alt="S'Wipe" height="32px"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav>
                            <Nav.Link href="/account">
                                <FontAwesomeIcon icon={faHome} size="sm" className="me-1"/>Home
                            </Nav.Link>
                            <Nav.Link href="/account/kits">
                                <FontAwesomeIcon icon={faVials} size="sm" className="me-1"/>Test Kits
                            </Nav.Link>
                            <Nav.Link href="/account/reports">
                                <FontAwesomeIcon icon={faFileLines} size="sm" className="me-1"/>Reports
                            </Nav.Link>
                            {user.isAdmin &&
                                <Nav.Link href="/account/admin" className="border border-danger">
                                    <FontAwesomeIcon icon={faUserTie} size="sm" className="me-1"/>Admin
                                </Nav.Link>
                            }
                            <NavDropdown title="Account" menuVariant="dark">
                                <NavDropdown.Item href="/account/details">
                                    <FontAwesomeIcon icon={faUser} size="sm" className="me-1"/>Profile
                                </NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item href="/" onClick={() => logOut()}>
                                    <FontAwesomeIcon icon={faRightFromBracket} size="sm" className="me-1"/>Log out
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet/>
            <Navbar fixed="bottom" className="bg-dark" variant="dark">
                <Nav fill className="small" style={{width: "100%"}}>
                    <Nav.Link href="/account">
                        <FontAwesomeIcon icon={faHome} size="lg"/>&nbsp;Home
                    </Nav.Link>
                    <Nav.Link href="/account/kits">
                        <FontAwesomeIcon icon={faVials} size="lg"/>&nbsp;Test Kits
                    </Nav.Link>
                    <Nav.Link href="/account/reports">
                        <FontAwesomeIcon icon={faFileLines} size="lg"/>&nbsp;Reports
                    </Nav.Link>
                    <Nav.Link href="/account/details">
                        <FontAwesomeIcon icon={faUser} size="lg"/>&nbsp;Profile
                    </Nav.Link>
                </Nav>
            </Navbar>
        </>
    )
}

export default ProtectedRoutes;