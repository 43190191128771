class Kit {
    kitId: string;
    numTests: number;
    usedTests: number;
    date: string;
    supplements: string[];
    alcoholConsumption: string;
    antibioticUse: string;

    constructor(kitId: string, numTests: number, date: string, supplements: string[],
                alcoholConsumption: string, antibioticUse: string) {
        this.kitId = kitId;
        this.numTests = numTests;
        this.date = date;
        this.supplements = supplements;
        this.alcoholConsumption = alcoholConsumption;
        this.antibioticUse = antibioticUse;
        this.usedTests = 0;
    }
}

export default Kit;