import React, {useRef} from "react";
import PageWizard from "../../../components/PageWizard";
import Intro from './addtestkit/Intro';
import ScanBarcode from "./addtestkit/ScanBarcode";
import Supplements from "./addtestkit/Supplements";
import AlcoholConsumption from "./addtestkit/AlcoholConsumption";
import AntibioticUse from "./addtestkit/AntibioticUse";
import Finish from "./addtestkit/Finish";
import {useNavigate} from "react-router-dom";
import {assignTestKit, updateUserKit} from "../../../firebase";
import {useUserAuth} from "../../../contexts/UserAuthContext";


const AddTestKit = () => {

    const pageWizardRef = useRef<{
        prev: () => void;
        next: () => void;
    }>(null);

    const prev = () => pageWizardRef.current?.prev();
    const next = () => pageWizardRef.current?.next();

    const {user} = useUserAuth();
    const [kitId, setKitId] = React.useState<string>("");
    const navigate = useNavigate();

    return (
        <PageWizard ref={pageWizardRef}>
            <Intro next={next} />
            <ScanBarcode prev={prev} next={(barcode) => {
                assignTestKit(user.uid, barcode)
                    .then(() => {
                        setKitId(barcode);
                        next();
                    });
            }}/>
            <Supplements prev={prev} next={(x) => {
                updateUserKit(user.uid, kitId, {supplements: x})
                    .then(next);
            }}/>
            <AlcoholConsumption prev={prev} next={(x) => {
                updateUserKit(user.uid, kitId, {alcoholConsumption: x})
                    .then(next);
            }}/>
            <AntibioticUse prev={prev} next={(x) => {
                updateUserKit(user.uid, kitId, {antibioticUse: x})
                    .then(next);
            }}/>
            <Finish prev={prev} next={() => navigate(-1)} uid={user.uid} kitId={kitId}/>
        </PageWizard>
    )
}

export default AddTestKit;