import React, {useState} from 'react';
import {Form, ToggleButton} from 'react-bootstrap';
// import 'react-multi-carousel/lib/styles.css';

const MultiOptionSelector = (
    {options, selectedOptions, setSelectedOptions, hasOtherOption, hasNoneOption}: {
        options: Array<string>,
        selectedOptions: Array<string>,
        setSelectedOptions: (selectedOptions: Array<string>) => void,
        hasOtherOption?: boolean,
        hasNoneOption?: boolean,
    }) => {

    if (hasOtherOption === undefined) {
        hasOtherOption = false;
    }

    if (hasNoneOption === undefined) {
        hasNoneOption = false;
    }

    const otherOptions = selectedOptions?.filter(x => !options.includes(x));
    const [otherOption, setOtherOption] = useState<string>(otherOptions?.length > 0 ? otherOptions[0] : '');
    const [includeOther, setIncludeOther] = useState<boolean>(otherOptions?.length > 0);

    const updateSelectedOptions =
        (oldValue: string, newValue: string, addValue: boolean) => {
            const selected = selectedOptions !== undefined ? selectedOptions.slice() : [];

            // Remove old value from selected
            const index = selected.indexOf(oldValue);
            if (index > -1) {
                selected.splice(index, 1);
            }

            if (addValue) {  // Add new value
                selected.push(newValue);
            }

            // Update selected options
            setSelectedOptions(selected);
        };

    return (
        <>
            {hasNoneOption &&
                <ToggleButton id="None" key="None" value="None" type="checkbox" className="rounded-5"
                              variant="outline-dark"
                              checked={selectedOptions === undefined || selectedOptions.length === 0}
                              onChange={(e) => {
                                  if (e.target.checked) {
                                      setSelectedOptions([]);
                                  }
                              }}>
                    None
                </ToggleButton>
            }
            {
                options.map((option) =>
                    <ToggleButton id={option.replace(' ', '-').toLowerCase()}
                                  key={option.replace(' ', '-').toLowerCase()}
                                  value={option} type="checkbox" className="rounded-5" variant="outline-dark"
                                  checked={selectedOptions !== undefined && selectedOptions.indexOf(option) > -1}
                                  onChange={(e) => {
                                      updateSelectedOptions(option, option, !selectedOptions?.includes(option));
                                  }}>
                        {option}
                    </ToggleButton>
                )
            }
            {hasOtherOption &&
                <Form.Control type="text" placeholder="Other" key="Other"
                              className={"rounded-5 text-center " + (includeOther ? "bg-dark text-light" : "bg-light text-dark")}
                              value={includeOther ? otherOption : ''}
                              onClick={() => {
                                  updateSelectedOptions(otherOption, otherOption, !includeOther);
                                  setIncludeOther(!includeOther);
                              }}
                              onChange={(e) => {
                                  const value = (e.target as HTMLInputElement).value;
                                  updateSelectedOptions(otherOption, value, includeOther);
                                  setOtherOption(value);
                              }}
                />
            }
        </>
    )
}

export default MultiOptionSelector;