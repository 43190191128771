import React from "react";
import {ToggleButton} from "react-bootstrap";
import Stack from "react-bootstrap/Stack";


const SingleOptionSelector = ({options, value, setValue}: {
    options: Array<string>,
    value: string | undefined,
    setValue: (x: string) => void
}) => {

    return (
        <Stack className="gap-md-3" gap={1}>
            {options.map((option, index) =>
                <ToggleButton id={option}
                              key={index}
                              value={option}
                              type="radio"
                              variant="outline-dark"
                              className="rounded-5"
                              checked={value === option}
                              onChange={() => setValue(option)}>
                    {option}
                </ToggleButton>
            )}
        </Stack>
    )
}

export default SingleOptionSelector;