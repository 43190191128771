import React, {useEffect} from 'react';
import StackWithButtons from "../../../../components/StackWithButtons";
import RoundedButton from "../../../../components/RoundedButton";
import {Link} from "react-router-dom";
import {getUserKit} from "../../../../firebase";
import Kit from "../../../../models/Kit";

const Finish = (props: {
    prev: () => void;
    next: () => void;
    uid: string;
    kitId: string;
}) => {

    const [kit, setKit] = React.useState<Kit>();

    useEffect(() => {
        getUserKit(props.uid, props.kitId)
            .then((kit) => {
                if (!!kit) {
                    setKit(kit);
                }
            })
    }, [props.uid, props.kitId]);

    return (
        <StackWithButtons title="Continue with Testing" prev={props.prev} className="my-5">
            <RoundedButton onClick={props.next} disabled={!kit} className="mt-5">
                Begin Testing
            </RoundedButton>
            <Link to="/account" className="mb-5">
                <RoundedButton variant="secondary">
                    Test Later
                </RoundedButton>
            </Link>
        </StackWithButtons>
    )
}

export default Finish;