import {useUserAuth} from "../../contexts/UserAuthContext";
import {getUsedTests, getUserReports} from "../../firebase";
import {Nav, Navbar, Stack, Table} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import Column from "../../components/Column";
import moment from 'moment';
import {Timestamp} from 'firebase/firestore';
import {getTestStatus, trim} from "../../utils/Utils";
import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Preview from "../../components/Preview";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faEye, faVial} from "@fortawesome/free-solid-svg-icons";

const Home = () => {

    const {userData, logOut, user} = useUserAuth();
    const [userReports, setUserReports] = useState([]);
    const [userTests, setUserTests] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getUserReports(user.uid)
            .then((reports) => setUserReports(reports));

        getUsedTests(user.uid)
            .then((tests) => setUserTests(tests));
    }, [user.uid]);

    const handleLogout = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            console.log(error.code, error.message);
        }
    }

    function formatDate(date) {
        if (date === undefined || date === null)
            return null;
        if (date instanceof Timestamp)
            date = date.toDate();
        return moment(date).format('MM/DD/YYYY');
    }

    return (
        <>
            <Column>
                <Stack className="my-3 gap-md-5 my-md-5" gap={3}>

                    <Preview header="Your profile" href="/account/details">
                        <Table className="table-sm table-borderless m-0" hover>
                            <tbody>
                            <tr>
                                <th>Name:</th>
                                <td>{userData.firstName} {userData.lastName}</td>
                            </tr>
                            <tr>
                                <th>Email:</th>
                                <td>{userData.email}</td>
                            </tr>
                            <tr>
                                <th>Diet:</th>
                                <td>{trim(userData.diets?.join(', '))}</td>
                            </tr>
                            {!!userData.gutIssues &&
                                <tr>
                                    <th>Gut issues:</th>
                                    <td>{trim(userData.gutIssues)}</td>
                                </tr>
                            }
                            </tbody>
                        </Table>
                    </Preview>

                    <Preview header="Your tests" href="/account/tests">
                        {!!userTests && userTests.length > 0
                            ? <Table className="table-sm m-0" hover>
                                <tbody>
                                {
                                    userTests.sort((x, y) => y.date - x.date)
                                        .slice(0, 3)
                                        .map((test, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{test.testId}</td>
                                                <td>{formatDate(test.date)}</td>
                                                <td className="small">{getTestStatus(test)}</td>
                                            </tr>
                                        ))
                                }
                                </tbody>
                            </Table>
                            : <p className="text-center">No tests available yet</p>
                        }
                    </Preview>

                    <Preview header="Your reports" href="/account/reports">
                        {!!userReports && userReports.length > 0
                            ? <Table className="table-sm m-0" hover>
                                <tbody>
                                {
                                    userReports.sort((x, y) => y.date - x.date)
                                        .slice(0, 3)
                                        .map((report, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{formatDate(report.date)}</td>
                                                <td>
                                                    <a href={report.downloadURL} target="_blank" rel="noreferrer">
                                                        <FontAwesomeIcon icon={faDownload} size="lg" className="me-1"/>
                                                        Download
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href={"/account/report/" + report.reportId}>
                                                        <FontAwesomeIcon icon={faEye} size="lg" className="me-1"/>
                                                        View
                                                    </a>
                                                </td>
                                            </tr>
                                        ))
                                }
                                </tbody>
                            </Table>
                            : <p className="text-center">No reports available yet</p>
                        }
                    </Preview>
                </Stack>
            </Column>
            <Navbar fixed="bottom" className="mb-5 mb-md-6">
                <Nav className="justify-content-center" style={{width: "100%"}}>
                    <Button className="rounded-5 m-3" variant="secondary"
                            onClick={() => navigate("/account/test")}>
                        <FontAwesomeIcon icon={faVial} size="lg" className="me-3"/>Begin Testing
                    </Button>
                </Nav>
            </Navbar>
        </>
    )
}

export default Home;