import React from 'react';
import Column from "../components/Column";
import {Form, Stack} from "react-bootstrap";
import RoundedButton from "../components/RoundedButton";
import {useUserAuth} from "../contexts/UserAuthContext";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

const ResetPassword = () => {
    const [validated, setValidated] = React.useState(false);
    const [email, setEmail] = React.useState(null);
    const {resetPassword} = useUserAuth();

    function handleSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() && email) {
            resetPassword(email).then(() => {
                alert("Reset password has been sent. Please follow the instructions in the email.")
            });
            setValidated(true);
        }
    }

    return (
        <Column>
            <Form validated={validated} onSubmit={handleSubmit}>
                <Stack className="d-flex mt-6" gap={3}>
                    <h1 className="text-center">Reset Password</h1>
                    <p>Enter your user account's verified email address and we will send you a password reset link</p>

                    <Form.Group>
                        <Form.Control type="email" value={email ?? ""} placeholder="Enter your email address"
                                      onChange={(e) => setEmail(e.currentTarget.value)}/>
                    </Form.Group>
                    <RoundedButton variant="primary" type="submit">Send password reset email</RoundedButton>
                    <Stack direction="horizontal" className="justify-content-center small" gap={3}>
                        <Link to="/">Home</Link>
                        <Link to={"mailto:info@bileomix.com"}>
                            <FontAwesomeIcon icon={faEnvelope} size={"1x"}/>&nbsp;Contact support
                        </Link>
                    </Stack>
                </Stack>
            </Form>
        </Column>
    )
}

export default ResetPassword;