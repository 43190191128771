class User {
    email: string;
    firstName: string;
    lastName: string;
    sex: string | undefined;
    gender: string | undefined;
    race: Array<string> | undefined;
    dateOfBirth: string | undefined;
    weight: number | undefined;
    weightUnit: string | undefined;
    height: number | undefined;
    heightUnit: string | undefined;
    diets: Array<string> | undefined;
    // gutConditions: string[] | undefined;
    // gutSymptoms: string[] | undefined;
    // orderId: string | undefined;
    // createdBy: string | undefined;

    constructor(fields: {email: string, firstName: string, lastName: string}) {
        this.email = fields.email;
        this.firstName = fields.firstName;
        this.lastName = fields.lastName;
        Object.assign(this, fields);
    }

    checkFields() {
        for (const key in this) {
            // Skip the fields that can be undefined
            // if (key in ['gutIssues', 'orderId', 'createdBy']) continue;
            if (typeof this[key] === "undefined") {
                return false;
            }
        }
        return true;
    }
}

export default User;