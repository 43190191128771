import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getAuth, isSignInWithEmailLink, signInWithEmailLink, updatePassword} from 'firebase/auth';
import {
    collection,
    collectionGroup,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where
} from 'firebase/firestore';
import {getBytes, getDownloadURL, getStorage, ref} from 'firebase/storage';
import User from "./models/User";
import Kit from "./models/Kit";
import Test from "./models/Test";

export function mapAuthCodeToMessage(authCode) {
    switch (authCode) {
        case 'auth/email-already-in-use':
            return 'Provided email already in use.'
        case 'auth/invalid-credential':
            return 'Invalid password.'
        case 'auth/too-many-requests':
            return 'Too many requests. Please try again later.'
        case 'auth/weak-password':
            return 'Password should be at least 6 characters.'
        case 'auth/network-request-failed':
            return 'Network connection has failed. Please try again.'
        case 'auth/invalid-action-code':
            return 'You cannot use this authentication method anymore.'
        case 'auth/invalid-email':
            return 'Invalid email address.'
        default:
            return undefined;
    }
}

const firebaseConfig = {
    apiKey: "AIzaSyBzQu-bk3uNzgEFK6LtKMb7MI2jxGOKuWs",
    authDomain: "arome-s-wipe-app.firebaseapp.com",
    projectId: "arome-s-wipe-app",
    storageBucket: "arome-s-wipe-app.appspot.com",
    messagingSenderId: "164180344810",
    appId: "1:164180344810:web:1d6565e73d3fae0786bc93",
    measurementId: "G-3Q1MGZ8XQ7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const users = collection(db, 'users');
export default app;


// export async function getUserReports(uid) {
//     const reportsRef = collection(db, "users", uid, 'reports');
//     return getDocs(reportsRef)
//         .then((querySnapshot) => {
//                 const reports = [];
//                 querySnapshot.forEach((report) => {
//                     reports.push(report.data());
//                 });
//                 return reports;
//             }
//         )
//         .catch((err) => {
//             console.error(err);
//             throw new Error(`Cannot access reports for user ${uid}`, {cause: err});
//         });
// }

export async function getUser(uid) {
    const userRef = doc(db, "users", uid);
    const userSnapshot = await getDoc(userRef);
    if (!userSnapshot.exists) {
        return null;
    }

    const userData = userSnapshot.data();
    return new User(uid, userData);
}

export async function getUserDocuments(uid, collectionName, condition) {
    let ref = collection(db, 'users', uid, collectionName);
    if (!!condition) {
        ref = query(ref, condition);
    }
    return getDocs(ref)
        .then((querySnapshot) => {
            const list = [];
            querySnapshot.forEach((snapshot) => {
                list.push(snapshot.data());
            });
            return list;
        })
        .catch((err) => {
            console.error(err);
            throw new Error(`Cannot access ${collectionName} for user ${uid}`, {cause: err});
        })
}


export async function getUserReports(uid) {
    const reports = await getUserDocuments(uid, "reports",
        where('emailed', "==", true));
    for (const report of reports) {
        const storageReference = ref(storage, report.file);
        report.downloadURL = await getDownloadURL(storageReference);
    }
    return reports;
}

export async function getUserReport(uid, reportId) {
    const reportSnapshot = await getDoc(doc(db, "users", uid, "reports", reportId));
    if (!reportSnapshot.exists())
        return null;

    return reportSnapshot.data();
}

export async function getFileBinary(path) {
    const fileReference = ref(storage, path);
    return await getBytes(fileReference);
}

export async function getFileURL(path) {
    const fileReference = ref(storage, path);
    return await getDownloadURL(fileReference);
}

export async function getUserTests(uid) {
    return getUserDocuments(uid, "tests");
}

// export async function createUserTest(uid, kitId, barcode) {
//     const testRef = doc(
//         db, "users", uid, "kits", kitId, "tests", barcode);
//
//     await setDoc(testRef, {
//         'barcode': barcode,
//         'date': new Date()
//     }).catch((err) => {
//         console.error(err);
//         throw new Error(`Cannot create the test data: ${err.message}`, {cause: err});
//     });
// }

export async function updateUserTest(uid, kitId, barcode, data){
    const testRef = doc(
        db, "users", uid, "kits", kitId, "tests", barcode);

    await updateDoc(testRef, data)
        .catch((err) => {
            console.error(err);
            throw new Error(`Cannot update the test data: ${err.message}`, {cause: err});
        })
}

export async function getUserKits(uid) {
    const kitsRef = collection(db, "users", uid, "kits");
    const kitsSnapshot = await getDocs(kitsRef);

    const kits = []
    for (const kitSnapshot of kitsSnapshot.docs) {
        const kitData = kitSnapshot.data();
        const kit = new Kit(kitData.kitId, kitData.numTests, kitData.date, kitData.supplements,
            kitData.alcoholConsumption, kitData.antibioticUse);

        const testsRef =
            collection(db, "users", uid, "kits", kitData.kitId, "tests");
        const testsQuery = query(testsRef, orderBy('date'));
        const countSnapshot = await getCountFromServer(testsQuery);
        kit.usedTests = countSnapshot.data().count;

        kits.push(kit);

        // const barcode = kitSnapshot.get('barcode');
        // let assignedKit = assignedKits[barcode];
        // if (!assignedKit) {
        //     assignedKit = new Kit(kitSnapshot.get('testingKitId'), 0, 0);
        //     assignedKits[barcode] = assignedKit;
        // }
        // assignedKit.testsTotal += 1;
        //
        // const testRef = doc(db, "users", uid, "tests", barcode);
        // const testSnapshot = await getDoc(testRef);
        // assignedKit.testsAvailable += Number(!testSnapshot.exists());
    }

    return kits;
}

export async function getUserKit(uid, kitId) {
    if (!kitId)
        return null;

    const kitRef = doc(db, "users", uid, "kits", kitId);
    const kitSnapshot = await getDoc(kitRef);
    if (!kitSnapshot.exists())
        return null;

    const kitData = kitSnapshot.data();
    return new Kit(kitData.kitId, kitData.numTests, kitData.date, kitData.supplements, kitData.alcoholConsumption,
        kitData.antibioticUse);
}

export async function updateUserKit(uid, kitId, data) {
    const kitRef = doc(db, "users", uid, 'kits', kitId);
    await updateDoc(kitRef, data);
}

export async function assignTestKit(uid, kitId) {
    const barcodesRef = collection(db, "barcodes");
    const barcodesQuery = query(barcodesRef,
        where('testingKitId', '==', kitId));
    const barcodesSnapshot = await getDocs(barcodesQuery);

    const tests = [];
    for (const barcodeSnapshot of barcodesSnapshot.docs) {
        const barcodeData = barcodeSnapshot.data();
        const test = new Test(barcodeData.barcode, barcodeData.testingKitId, barcodeData.tubeNumber);
        tests.push(test);
        // await updateDoc(barcodeSnapshot.ref, {'assignedTo': uid});
    }

    const kitRef = doc(db, 'users', uid, 'kits', kitId);
    await setDoc(kitRef, {
        'kitId': kitId,
        'date': new Date(),
        'numTests': barcodesSnapshot.docs.length,
    });

    for (const test of tests) {
        const testRef = doc(db, 'users', uid, 'kits', kitId, 'tests', test.testId);
        await setDoc(testRef, {
            barcode: test.testId,
            testId: test.testId,
            kitId: test.kitId,
            tubeNumber: test.tubeNumber,
        });
    }
}

export async function getAvailableTests(uid) {
    const kitsRef = collection(db, 'users', uid, 'kits');
    const kitsQuery = query(kitsRef, orderBy('date', 'desc'), limit(1));
    const kitsSnapshot = await getDocs(kitsQuery);

    if (kitsSnapshot.docs.length === 0) {
        return [];
    }

    const kitSnapshot = kitsSnapshot.docs[0];

    const testsRef = collection(
        db, 'users', uid, 'kits', kitSnapshot.get('kitId'), 'tests');
    const testsSnapshot = await getDocs(testsRef);

    const tests = [];
    for (const testSnapshot of testsSnapshot.docs) {
        const testData = testSnapshot.data();
        const test = new Test(testData.testId, testData.kitId, testData.tubeNumber);
        if (test.hasOwnProperty('date') && !!testData.date) {
            test.date = testData.date.toDate();
        }

        tests.push(test);
    }

    tests.sort((a, b) => {
        // Compare kit IDs
        if (a.kitId !== b.kitId) {
            return b.kitId - a.kitId;
        }

        // If kit IDs are equal, sort by tube number
        return a.tubeNumber - b.tubeNumber;
    });

    return tests;
}

export async function getUsedTests(uid, limit) {
    if (limit === undefined)
        limit = 3;

    const kitsRef = collection(db, 'users', uid, 'kits');
    const kitsQuery = query(kitsRef, orderBy('date', 'desc'));
    const kitsSnapshot = await getDocs(kitsQuery);

    const tests = [];
    for (const kitSnapshot of kitsSnapshot.docs) {
        const testsRef =
            collection(db, 'users', uid, 'kits', kitSnapshot.get('kitId'), 'tests');
        const testsQuery = query(testsRef, orderBy('date', 'desc'));
        const testsSnapshot = await getDocs(testsQuery);

        for (const testSnapshot of testsSnapshot.docs) {
            const testData = testSnapshot.data();
            const test = new Test(testData.testId, testData.kitId, testData.tubeNumber, testData.date);
            tests.push(test);

            if (tests.length >= limit) break;
        }

        if (tests.length >= limit) break;
    }

    return tests;
}

export async function completeSignInWithEmailLink(email, link, password) {
    if (!email || !isSignInWithEmailLink(auth, link)) return null;
    return signInWithEmailLink(auth, email, link)
        .then((userCredentials) => {
            const user = userCredentials.user;
            return updatePassword(user, password)
                .then(() => console.log(`Set new password for user ${user.uid}`));
        });
}

export async function checkTestKitIsAvailable(barcode) {
    const barcodesRef = collection(db, "barcodes");
    const barcodesQuery = query(barcodesRef,
        where('testingKitId', '==', barcode));

    const barcodesSnapshot = await getDocs(barcodesQuery);
    const testingKitIds = new Set();
    for (const barcodeSnapshot of barcodesSnapshot.docs) {
        // const assignedTo = barcodeSnapshot.get('assignedTo');  // TODO: uncomment
        // if (!assignedTo) {
        testingKitIds.add(barcodeSnapshot.get('testingKitId'));
        // }
    }

    return testingKitIds.size === 1;
}