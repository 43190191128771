import React, {Component, ForwardedRef, forwardRef, ReactNode, useImperativeHandle, useState} from 'react';
import {ProgressBar, Stack} from "react-bootstrap";
import Column from "./Column";

// class PageWizard<State extends PageWizardState> extends Component<{}, State & PageWizardState> {
//
//     state = {
//         activeStep: 0,
//         steps: []
//     }
//
//     // constructor(props: {}) {
//     //     super(props);
//     //
//     //     this.state = {
//     //         activeStep: 0,
//     //         steps: []
//     //     }
//     // }
//
//     setSteps(steps: Array<ReactNode>) {
//         this.setState({
//             activeStep: 0,
//             steps: steps
//         })
//     }
//
//     next = () => {
//         this.setState({
//             activeStep: this.state.activeStep + 1
//         });
//     }
//
//     prev = () => {
//         this.setState({
//             activeStep: this.state.activeStep - 1
//         });
//     }
//
//     private getSectionComponent() {
//         if (this.state.steps.length === 0) {
//             return null;
//         }
//
//         if (this.state.activeStep < 0 || this.state.activeStep >= this.state.steps.length) {
//             this.setState({activeStep: 0});
//         }
//         return this.state.steps[this.state.activeStep];
//     }
//
//     render() {
//         return (
//             <>
//                 <ProgressBar now={100 * (1 + this.state.activeStep) / this.state.steps.length}
//                              variant="secondary" style={{height: "5px"}}/>
//                 <Column>
//                     <Stack className="d-flex text-center vh-custom p-1 gap-md-3 p-md-3" gap={1}>
//                         {this.getSectionComponent()}
//                     </Stack>
//                 </Column>
//             </>
//         )
//     }
// }

interface PageWizardProps {
    children: Array<ReactNode>;
}

const PageWizard = forwardRef((props: PageWizardProps, ref) => {
    useImperativeHandle(ref, () => ({
        next() {
            setActiveStep(activeStep + 1);
        },
        prev() {
            setActiveStep(activeStep - 1);
        }
    }));

    const [activeStep, setActiveStep] = useState(0);

    function getSectionComponent() {
        if (props.children.length === 0) {
            return null;
        }

        if (activeStep < 0 || activeStep >= props.children.length) {
            setActiveStep(0);
        }
        return props.children[activeStep];
    }

    return (
        <>
            <ProgressBar now={100 * (1 + activeStep) / props.children.length}
                         variant="secondary" style={{height: "5px"}}/>
            <Column>
                <Stack className="d-flex text-center vh-custom p-1 gap-md-3 p-md-3" gap={1}>
                    {getSectionComponent()}
                </Stack>
            </Column>
        </>
    )
})

// const PageWizard = (props: {
//     children: Array<ReactNode>;
// }) => {
//
//     const [activeStep, setActiveStep] = useState(0);
//
//
//
//     function getSectionComponent() {
//         if (props.children.length === 0) {
//             return null;
//         }
//
//         if (activeStep < 0 || activeStep >= props.children.length) {
//             setActiveStep(0);
//         }
//         return props.children[activeStep];
//     }
//
//     return (
//         <>
//             <ProgressBar now={100 * (1 + activeStep) / props.children.length}
//                          variant="secondary" style={{height: "5px"}}/>
//             <Column>
//                 <Stack className="d-flex text-center vh-custom p-1 gap-md-3 p-md-3" gap={1}>
//                     {getSectionComponent()}
//                 </Stack>
//             </Column>
//         </>
//     )
// }

export default PageWizard;