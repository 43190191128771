import React from "react";
import Col from "react-bootstrap/Col";

function Column({children, ...props}) {
    return (
        <Col md={{span: 6, offset: 3}} className={"pb-6 overflow-auto " + (props.className ?? '')} {...props}>
            {children}
        </Col>
    )
}

export default Column;